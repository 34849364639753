import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Signature from "./signature.js";

let Hello = props => <h1>{props.children}</h1>;
let Link = props => (
  <a href={props.href} rel="noopener" target="_blank">
    {props.children}
  </a>
);
ReactDOM.render(
  <div className="main">
    <div className="links">
      <Link href="/OhMorgan_Resume.pdf">Resume</Link>
      <Link href="https://www.linkedin.com/in/learnaboutmorgan/">LinkedIn</Link>
      <Link href="https://github.com/learnaboutmorgan/">GitHub</Link>
      <Link href="https://medium.com/@learnaboutmorgan/">Medium</Link>
    </div>
    <div className="sig-wrapper">
      <Hello>Hi, I'm Morgan </Hello>
      {/* <Signature /> */}
    </div>
  </div>,
  document.getElementById("root")
);
